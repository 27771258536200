import { CmsPage } from '@models'
import Button from '@components/ui/Button'
import Link from 'next/link'
import { useStoreDataContext } from '@contexts/storeDataContext/useStoreDataContext'
import { BlogBanner } from './components/BlogBanner'

interface BlogsSectionProps {
  banners?: CmsPage[]
}

export const BlogsSection = ({ banners }: BlogsSectionProps) => {
  const {
    data: {
      attributes: { all_blogs_url },
    },
  } = useStoreDataContext()

  if (!banners) {
    return null
  }

  return (
    <section className='w-full '>
      <h2 className='text-center sm:text-32 text-xl font-bold uppercase '>
        Blogs
      </h2>
      <div className='py-7 grid grid-cols-1 sm:grid-cols-3 gap-7'>
        {banners.slice(0, 3).map(el => (
          <BlogBanner banner={el} key={el.id} />
        ))}
      </div>
      {all_blogs_url ? (
        <div className='flex sm:justify-center'>
          <Link
            href={all_blogs_url}
            className=' w-full sm:w-auto'
            passHref
            target='_blank'
          >
            <Button
              variant='brand'
              className='flex items-center w-full sm:w-auto'
              data-testid='banner_shop_now_button'
              size='medium'
            >
              View All Blogs
              <svg
                className='ml-2 hidden md:block'
                xmlns='http://www.w3.org/2000/svg'
                width='21'
                height='10'
                viewBox='0 0 21 10'
                fill='none'
              >
                <path
                  d='M15.5125 3.75H0.5V6.25H15.5125V10L20.5 5L15.5125 0V3.75Z'
                  fill='white'
                />
              </svg>
            </Button>
          </Link>
        </div>
      ) : null}
    </section>
  )
}
